import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { bool, func, object, string } from "prop-types";
import { useDispatch } from "react-redux";
import { confirmUpcomingWorkshop } from "../../../store/actions/employer/confirmUpcomingWorkshop.action";
import redCalendarIcon from "../../../assets/images/redCalendarIcon.svg"
import blueCalendarIcon from "../../../assets/images/blueCalendarIcon.svg"
import moment from "moment";

function UpcomingWorkshop(props) {
  const dispatch = useDispatch();
  const elem = props.data;
  //To handle the confirm button in upcomingWorkshop
  const handleConfirm = () => {
    var payload = {
      lesson_id: elem.lesson_id,
      student_id: elem.student_id,
    };
    dispatch(confirmUpcomingWorkshop.request(payload));
  };

  return props.dataFor === "beThere" ? (
    <div className={styles.beThereBox}>
      <div className={styles.workshopLabelbeThere}>
     
          <span className={`${styles.details}`}>
      Upcoming Workshop
          </span>
      
          <span className={`${styles.dateinfobeThere}`}>
            {elem.date}
          </span>
      </div>
      <div className={`${styles.buttonBoxForBeThere}`}>
        <button className={styles.confirm} onClick={props?.onClick}>
          I'll be there
        </button>
        <Link to={props?.contactPath} className={styles.linkColor}>
          <button className={styles.contact}>Contact CTI</button>
        </Link>
      </div>
    </div>
  ) : (
    <div className={styles.workshopbox}>
      <div className={styles.workshopLabel}>
        <ul>
          <li className={elem?.status ==="F_Rescheduled" ? styles.crossedDetails: styles.details}>
            {props?.dataFor === "submitReminder" ? (
              "Submit Worklog"
            ): props?.dataFor === "theoryAssessments" ? (
              "Theory Assessment"
            ): (
              <>{props.workshopName}</>
            )}
          </li>
          <li className={`${styles.draftList}`}>
            <span className={`${styles.draft}`}>
              {props.dataFor!=="student"&& elem?.name}
            </span>
          </li>
          <li className={elem?.status ==="F_Rescheduled" ? styles.crossedDateInfo: styles.dateinfo}>
            {  props.dataFor==="theoryAssessments"? elem?.sam_assessmentcode: (props.dataFor!=="submitReminder" && props.dataFor!=="theoryAssessments") ? elem?.date : <br/>}
            {props.dataFor==="theoryAssessments" && <div className={moment.utc(elem?.sam_assessmentduedate) < moment(new Date()) ?styles.assessmentOverDue: styles.assessmentNotOverDue}>
               <img alt="Red Calendar Icon" src={moment.utc(elem?.sam_assessmentduedate) < moment(new Date()) ?redCalendarIcon : blueCalendarIcon}/>
               <span>{moment(elem?.sam_assessmentduedate).format('DD/MM/YYYY')}</span>
              </div>}
          </li>
        </ul>
      </div>

      {props?.label ? (
        <>
          <div className={elem?.status === "Not Attended" ? styles.statusnotAttended :elem?.status === "P_Rescheduled" ?styles.statusRescheduled:  styles.statusAttended}>{elem?.status === "P_Rescheduled" ?"Rescheduled": elem?.status}</div>
        </>
      ) : elem?.status ==="F_Rescheduled" ?
      <div className={styles.statusFRescheduled}>{"Rescheduled"}</div>
      :(
        <div className={`${styles.buttonBox}`}>
          {props?.confirm && (
            <button className={styles.confirm} onClick={handleConfirm}>
              Confirm
            </button>
          )}
          {props?.dataFor === "beThere" && (
            <button className={styles.confirm} onClick={props?.onClick}>
              I'll be there
            </button>
          )}
          {(props?.dataFor === "submitReminder")? (
            <Link to={props?.contactPath}>
              <button className={styles.submitReminder}>Submit</button>
            </Link>
          ):  props?.dataFor === "theoryAssessments" ? <a
          href={`https://cti.assessapp.com/assessment_records/${elem.sam_recordid}/edit`}
          target="_blank"
          rel="noopener noreferrer"
        ><button className={styles.submitReminder}>Submit</button></a>:<></>}
          {(props?.dataFor !== "submitReminder" && props?.dataFor !== "theoryAssessments") && (
            <Link to={props?.contactPath} className={styles.linkColor}>
              <button className={styles.contact}>Contact CTI</button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}
UpcomingWorkshop.propTypes = {
  data:object,
  dataFor:string,
  contactPath:string,
  label:bool,
  onClick:func,
  workshopName:string,
  


};

export default UpcomingWorkshop;
