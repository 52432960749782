import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./style.module.scss";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { apprenticeStudentData } from "../../../store/actions/employer/apprenticeStudent.action";
import { apprenticeStudentTodoData } from "../../../store/actions/employer/apprenticeStudentTodo.action";
import { useSelector } from "react-redux";
import PrevUpButton from "../PrevUpButtons";
import UpcomingWorkshop from "../UpcomingWorkshop";
import loadinWheel from "../../../assets/images/blue-Loading-spinner.gif";
import WorklogUnit from "../WorklogUnit";
import { saveId } from "../../../store/actions/employer/apprenticeStudentTodo.action";
import EmployerStudentHeader from "../EmployerStudentHeader/index";
import HeaderLabel from "../../common/HeaderLabel/index";
import { employer_path } from "../../../Constants/paths/employer";
import ProgressReport from "../ProgressReport/index";
import StageNavigation from '../SingleStudentLayout/StageNavigation/index';
import { studentOntrackData } from "../../../store/actions/employer/StudentOnTrackStatus.action";
import ProgressConstants from "./ProgressConstants/ProgressConstants";
import overDueWaningIcon from "../../../assets/images/overdueWarningIcon.svg"

const SingleStudentLayout = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const { id } = useParams();
  const dispatch = useDispatch();

  //Request Data on the render time for the student
  useEffect(() => {
    dispatch(apprenticeStudentData.request(id));
    dispatch(apprenticeStudentTodoData.request(id));
    dispatch(saveId(id));
    dispatch(studentOntrackData.request(id))
  }, []);

  const StudentData = useSelector(
    (state) => state.employerReducers?.apprenticeStudent
  );
  const StudentDataTodo = useSelector(
    (state) => state.employerReducers?.apprenticeStudentTodo
  );
  const confirmationReducer = useSelector(
    (state) => state.employerReducers?.confirmUpcomingWorkshopReducer
  );
  //To fetch the employer data from the reducer
  const StudentProfileDetails = useSelector(
    (state) => state.employerReducers?.employerStudentProfile
  );
  //fetch the progress report
  const studentProgressReportDetails = useSelector(
    (state) => state.employerReducers?.StudentProgressReport.progressReport
);
  //To fetch the status of student data from the reducer
  const studentOntrackStatus = useSelector(
    (state) => state.employerReducers?.StudentOnTrackStatus
  );
  const { studentProfile } = StudentProfileDetails
  const { onTrackStatus } = studentOntrackStatus
  //Create stub for the worklog Unit Signature
  const WorklogUnitdata = StudentDataTodo?.studenttodo?.worklogs;
  const upcomingWorkshop = StudentDataTodo?.studenttodo?.upcoming_lessons;
  const loadingWorklogs = StudentDataTodo?.loading;
  const loadingStudents = StudentData?.loading;
  const filterData = StudentData?.student;
  const loadingConfirmation = confirmationReducer?.loading;
  const confirmationSuccess = confirmationReducer?.confirmsuccess;

  //Request Data on the render time for the student after the confirm success
  useEffect(() => {
    dispatch(apprenticeStudentTodoData.request(id));
    dispatch(apprenticeStudentData.request(id));
  }, [confirmationSuccess]);

  //data filter for the Upcoming and Previous
  const upcoming_Workshop = filterData?.filter((elem) => {
    return elem.status === "Future" || elem.status === "F_Rescheduled";
  });
  const previous_Workshop = filterData?.filter((elem) => {
    return elem.status !== "Future" && elem.status !=="Not Booked" &&  elem.status !== "F_Rescheduled";
  });

  //To handle the Upcoming and Previous button
  const [activestate, setactivestate] = useState(true);
  const handleUpcoming = () => {
    setactivestate(true);
  };
  const handlePrevious = () => {
    setactivestate(false);
  };

  //count for the student workshops

  const totalWorkshops =  filterData?.filter(item=> (item.status==="Future" || item.status === "Attended" || item.status === "Not Booked")).length
  const attendedWorkshops = filterData?.filter(item=> (item.status === "Attended")).length
  
  //Inline style for the Header
  const style = { width: '100vw', marginTop: "-70px", marginLeft: '-15px' }
  return (
    <>
      {!loadingStudents && !loadingWorklogs && !loadingConfirmation ? (
        <div>
          <EmployerStudentHeader id={id} />
          <div className={`${styles.studentTrackingAndStage}`}>
            <span className={`${styles.ontrack}`} style={{ color: onTrackStatus.status === "Behind" ? "red" : "" }} >{onTrackStatus.status}</span>
            <div className={`${styles.stage}`}>
              <span className={`${styles.stagetext}`}>Stage</span>
              <div className={`${styles.pagination}`}>
                <StageNavigation id={id} date={studentProfile.start_date} />
              </div>
            </div>
          </div>
          {studentProfile.overdue_theory_assessments >0 &&
                    <div className={styles.overdueTheoryAssessmentsCountContainer}>
                    <div>
                      <img src={overDueWaningIcon} alt = "Ovedue Warning Icon" />
                      <span>Theory</span>
                    </div>
                    <div className={styles.overdueTheoryAssessmentsCountText}>
                      { studentProfile.overdue_theory_assessments + " Overdue"}
                    </div>
                  </div>
          }

          <HeaderLabel text="To Do" style={style} />
          <div className={styles.boxTopmargin}>
            {upcomingWorkshop?.length > 0 || WorklogUnitdata?.length > 0 ? (
              <></>
            ) : (
              <div className={styles.allSetLabel}>You are all set</div>
            )}
                    {/* this container contains the progress bar of workshops */}
        {
          <div className={styles.workshopsCompleted}>
            <div className={styles.workshopCompletedLabel}>
              <div className={`${styles.details}`}>Workshops Completed</div>
              <div className={`${styles.details}`}>{attendedWorkshops || 0 } / <span className={styles.totalWorkshops}>{totalWorkshops || 0}</span></div>
            </div>

            <div className={styles.statusField}>
                  <div className={styles.activeStatus}>
                    <div
                      className={styles.statusBar}
                      style={{ width: (attendedWorkshops/totalWorkshops)*100 + "%" }}
                    ></div>
                  </div>              
            </div>
          </div>
        }
            {upcomingWorkshop?.map((elem) => (
              <UpcomingWorkshop
                data={elem}
                confirm={true}
                contactPath={employer_path.EMPLOYER_CONTACT_US}
                workshopName="Upcoming WorkShop"
              />
            ))}
          </div>
          {WorklogUnitdata?.map((elem) => (
            <WorklogUnit
              employerData={elem}
              text="Worklog Sign"
              path={employer_path.EMPLOYER_STUDENT_WORKLOG_SIGN}
              student={true}
            />
          ))}
          <div className={styles.UnderLine}></div>
          <PrevUpButton
            onUpcomingClick={handleUpcoming}
            onPrevClick={handlePrevious}
            activestate={activestate}
          />
          {upcoming_Workshop?.length > 0 ? (
            <></>
          ) : (
            <div className={styles.allSetLabel}>
              There are no {activestate ? "upcoming" : "previous"} workshops
            </div>
          )}
          {activestate ? (
            <>
              {upcoming_Workshop?.map((elem) => (
                <UpcomingWorkshop
                  key={elem.lesson_id}
                  data={elem}
                  contactPath={employer_path.EMPLOYER_CONTACT_US}
                  workshopName="Upcoming WorkShop"
                />
              ))}
            </>
          ) : (
            <>
              {previous_Workshop?.map((elem) => (
                <UpcomingWorkshop
                  key={elem.lesson_id}
                  data={elem}
                  label={true}
                  workshopName="Previous WorkShop"
                  contactPath={employer_path.EMPLOYER_CONTACT_US}
                />
              ))}
            </>
          )}
          <div className={styles.UnderLine}></div>
          <div className={`${styles.viewWorklog}`}>
            <Link to={`/employer/student/${id}/worklog/submission`}>
              <button className={`${styles.viewWorklogBtn}`}>
                View Worklogs
              </button>
            </Link>
          </div>
          <ProgressReport id={id} />
          {studentProgressReportDetails.length > 0 && 
          <ProgressConstants />
}
        </div>
      ) : (
        <div className={styles.loadingWheel}>
          <img src={loadinWheel} alt="loadinWheel" />
        </div>
      )}
    </>
  );
};

export default SingleStudentLayout;
